import React from 'react';

import { Associations } from './associations';
import { BusinessAddress } from './business-address';
import { Copyright } from './copyright';

export const Footer = () => {
  return (
    <footer>
      <Associations />
      <BusinessAddress />
      <Copyright />
    </footer>
  );
};
