import { Link } from 'gatsby';
import React from 'react';

import * as menuStyles from './menu.module.css';
import { socialLinks } from './social';

const pages = [
  { pathname: '/', name: 'Home' },
  { pathname: '/services/', name: 'Our services' },
  { pathname: '/contact/', name: 'Contact' },
];

type MenuProps = {
  children?: React.ReactNode;
  withSocialLinks?: boolean;
  isSticky?: boolean;
};

export const Menu = ({ children, withSocialLinks, isSticky }: MenuProps) => {
  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <section className={isSticky ? menuStyles.stickyMenu : menuStyles.menu}>
      <ul className={menuStyles.item}>
        {pages.map(({ name, pathname: path }) => (
          <li key={path} className={menuStyles.list}>
            <Link
              suppressHydrationWarning
              className={menuStyles.link}
              to={path}
            >
              {pathname === path ? `➝ ${name}` : name}
            </Link>
          </li>
        ))}
      </ul>
      {withSocialLinks && (
        <ul className={menuStyles.socialLinks}>
          {socialLinks.map(({ href, name }) => (
            <li className={menuStyles.list} key={name}>
              <a className={menuStyles.socialLink} href={href}>
                {name}
              </a>
            </li>
          ))}
        </ul>
      )}
      {children}
    </section>
  );
};
