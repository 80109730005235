import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import * as headerStyles from './header.module.css';
import { Menu } from './menu';

type HeaderProps = {
  children: React.ReactNode;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Logo = () => (
  <Link to="/" className={headerStyles.hero}>
    <StaticImage
      loading="eager"
      placeholder="none"
      alt="The Bridge & Associates Appraisals Ltd. logo"
      src="../images/logo.svg"
    />
  </Link>
);

type HamburgerProps = {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Hamburger = ({ setIsMenuOpen }: HamburgerProps) => {
  return (
    <section
      className={headerStyles.hamburgerContainer}
      onClick={async () => {
        await scrollToTop();
        setIsMenuOpen((isMenuOpen: boolean) => !isMenuOpen);
      }}
    >
      <div className={headerStyles.hamburger} />
      <div className={headerStyles.hamburger} />
      <div className={headerStyles.hamburger} />
    </section>
  );
};

const scrollToTop = () =>
  new Promise((resolve) => resolve(window.scrollTo(0, 0)));

export const Header = ({ setIsMenuOpen, children }: HeaderProps) => {
  return (
    <header className={headerStyles.header}>
      <section className={headerStyles.headerContent}>
        <Logo />
        <Hamburger setIsMenuOpen={setIsMenuOpen} />
      </section>
      <Menu withSocialLinks isSticky>
        {children}
      </Menu>
    </header>
  );
};
