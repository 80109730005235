import React from 'react';

import * as socialStyles from './social.module.css';

export const socialLinks = [
  {
    href: 'https://facebook.com/Bridge-Associates-Appraisals-393001271065239/',
    name: 'Facebook',
  },
  {
    href: 'https://www.linkedin.com/company/bridge-associates-appraisals-ltd',
    name: 'LinkedIn',
  },
  {
    href: 'https://cnarea.ca/web-2-0-directory/bridge-martin/',
    name: 'CNAREA',
  },
  {
    href: 'https://www.yellowpages.ca/bus/British-Columbia/Qualicum-Beach/Bridge-Associates-Appraisals-Ltd/101305403.html',
    name: 'Yellow Pages',
  },
];

export const Social = () => {
  return (
    <ul className={socialStyles.socialMenu}>
      {socialLinks.map(({ name, href }) => (
        <li key={name}>
          <a className={socialStyles.socialLink} href={href}>
            {name}
          </a>
        </li>
      ))}
    </ul>
  );
};
