import { useLocation } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

type BodyAttributes = {
  class?: string;
};

type HtmlAttributes = {
  lang: string;
};

type AdditionalAttributes = {
  bodyAttributes: BodyAttributes;
  htmlAttributes: HtmlAttributes;
};

type SEOProps = {
  title: string;
  description: string;
  image: string;
  additionalAttributes: AdditionalAttributes;
};

export const SEO = ({
  title,
  description,
  image,
  additionalAttributes,
}: SEOProps) => {
  const { pathname, origin, hostname } = useLocation();

  const isGastbyIO = hostname === 'bridgeandassociatesmaster.gatsbyjs.io';

  const seo = {
    title,
    description,
    image: `${origin}${image}`,
    url: `${origin}${pathname}`,
  };

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        name: 'Bridge & Associates Appraisals Ltd.',
        telephone: '+12502405950',
        email: 'cmb.appraiser@gmail.com',
        url: seo.url,
        address: {
          '@type': 'PostalAddress',
          streetAddress: '590 Nassau Crescent',
          addressLocality: 'Qualicum Beach',
          addressRegion: 'British Columbia',
          addressCountry: 'Canada',
          postalCode: 'V9K 1C6',
        },
        openingHoursSpecification: [
          {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            opens: '8:00',
            closes: '18:00',
          },
        ],
        geo: {
          '@type': 'GeoCoordinates',
          latitude: 49.35011182343135,
          longitude: -124.3901546865038,
        },
      })}
    </script>
  );

  return (
    <Helmet title={seo.title} {...additionalAttributes}>
      {isGastbyIO && <meta name={`robots`} content={`noindex`} />}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content="website" />
      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {structuredData}
    </Helmet>
  );
};
