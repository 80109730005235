import React, { useState } from 'react';

import { Footer } from './footer';
import * as layoutStyles from './layout.module.css';
import { Navigation } from './navigation';
import { SEO } from './seo';

type ChildrenProps = {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type LayoutProps = {
  title: string;
  description: string;
  image?: string;
  children: ({ setIsMenuOpen }: ChildrenProps) => JSX.Element;
};

export const Layout = ({
  children,
  title,
  description,
  image = '/bridge-and-associates-appraisals-ltd.png',
}: LayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const element = children({ setIsMenuOpen });
  const bodyAttributes = isMenuOpen
    ? {
        class: layoutStyles.noscroll,
      }
    : {};

  const additionalAttributes = {
    bodyAttributes,
    htmlAttributes: {
      lang: 'en',
    },
  };

  return (
    <>
      <SEO
        title={title}
        description={description}
        image={image}
        additionalAttributes={additionalAttributes}
      />
      <Navigation isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main>{element}</main>
      <Footer />
    </>
  );
};
