import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import * as businessAddressStyles from './business-address.module.css';

export const BusinessAddress = () => {
  return (
    <section className={businessAddressStyles.businessAddressContainer}>
      <a
        href="https://goo.gl/maps/fLAY7ELYdAGfL39t8"
        className={businessAddressStyles.businessAddressLink}
      >
        <address className={businessAddressStyles.businessAddress}>
          Bridge & Associates Appraisals Ltd.
          <br />
          590 Nassau Crescent
          <br />
          Qualicum Beach, BC V9K 1C6
        </address>
        <StaticImage
          width={40}
          src="../images/m_i.png"
          alt="Google Maps icon"
        />
      </a>
    </section>
  );
};
