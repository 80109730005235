import React from 'react';

import { ContactInformation } from './contact';
import { Menu } from './menu';
import * as navigationStyles from './navigation.module.css';
import { Social } from './social';

type HiddenMobileNavigationProps = {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HiddenMobileNavigation = ({
  setIsMenuOpen,
}: HiddenMobileNavigationProps) => (
  <>
    <Menu>
      <section
        className={navigationStyles.x}
        onClick={() => setIsMenuOpen((isMenuOpen: boolean) => !isMenuOpen)}
      >
        <div className={navigationStyles.firstX} />
        <div className={navigationStyles.secondX} />
      </section>
    </Menu>
    <Social />
  </>
);

type NavigationProps = {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Navigation = ({ isMenuOpen, setIsMenuOpen }: NavigationProps) => {
  return (
    <nav
      className={
        isMenuOpen ? navigationStyles.maximized : navigationStyles.minimized
      }
    >
      <ContactInformation />
      <HiddenMobileNavigation setIsMenuOpen={setIsMenuOpen} />
    </nav>
  );
};
