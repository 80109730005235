import React from 'react';

import * as contactStyles from './contact.module.css';

export const ContactInformation = () => {
  return (
    <ul className={contactStyles.contact}>
      <li>
        <a className={contactStyles.link} href="tel:+12502405950">
          (250) 240-5950
        </a>
      </li>
      <li>
        <a className={contactStyles.link} href="mailto:cmb.appraiser@gmail.com">
          cmb.appraiser@gmail.com
        </a>
      </li>
    </ul>
  );
};
