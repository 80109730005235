import React, { Dispatch, SetStateAction } from 'react';

import { Header } from './header';
import * as indexHeroStyles from './hero.module.css';

type HeaderContentProps = {
  slogan: string;
  noArrow?: boolean;
};

const HeaderContent = ({ slogan, noArrow }: HeaderContentProps) => (
  <section className={indexHeroStyles.sloganContainer}>
    <h1 className={indexHeroStyles.slogan}>{slogan}</h1>
    {!noArrow && <span className={indexHeroStyles.arrow}>⭣</span>}
  </section>
);

type HeroProps = {
  children?: React.ReactNode;
  slogan: string;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  image: React.ReactNode;
  noArrow?: boolean;
};

export const Hero = ({
  setIsMenuOpen,
  children,
  slogan,
  image,
  noArrow,
}: HeroProps) => {
  return (
    <section className={indexHeroStyles.grid}>
      {image}
      <section className={indexHeroStyles.hero}>
        <Header setIsMenuOpen={setIsMenuOpen}>
          <HeaderContent slogan={slogan} noArrow={noArrow} />
        </Header>
        {children}
      </section>
    </section>
  );
};
